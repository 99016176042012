import React from "react";
import ReactDOM from "react-dom/client";
import "./css/index.css";
import reportWebVitals from "./reportWebVitals";

//Routes
import { Landing } from "./views/landingPage";
/* import { SignUp } from './views/signup'; */
import { Cities } from "./views/cities";
import { Airports } from "./views/airports";
import { Legal } from "./views/legal";
import { Investors } from "./views/investors";
import { Updates } from "./views/updates";
import { Careers } from "./views/careers";
import { Blogs } from "./views/blogs";
import { Blog } from "./views/blog";
import { Career } from "./views/career";
import { NotFound } from "./views/404";
import { ContactUs } from "./views/contact";

//Router
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import DriverPrivacyPolicy from "./views/driverPolicy";
import PrivacyPolicy from "./views/riderPolicy";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Landing />,
  },
  {
    path: "/:section",
    element: <Landing />,
  },
  /* {
    path: "/signup",
    element: <SignUp />,
  }, */
  {
    path: "/cities",
    element: <Cities />,
  },
  {
    path: "/airports",
    element: <Airports />,
  },
  {
    path: "/ad/legal",
    element: <Legal />,
  },
  {
    path: "/ad/investors/:id",
    element: <Investors />,
  },
  {
    path: "/ad/updates/:id",
    element: <Updates />,
  },
  {
    path: "/ad/blogs",
    element: <Blogs />,
  },
  {
    path: "/ad/careers",
    element: <Careers />,
  },
  {
    path: "/blog/:id",
    element: <Blog />,
  },
  {
    path: "/career/:id",
    element: <Career />,
  },
  {
    path: "/contact",
    element: <ContactUs />,
  },
  {
    path: "/app/driver/policy",
    element: <DriverPrivacyPolicy />,
  },
  {
    path: "/app/rider/policy",
    element: <PrivacyPolicy />,
  },
  {
    path: "*",
    element: <NotFound />,
  },
]);

/* ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);
 */
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
