import React from "react";

const DriverPrivacyPolicy = () => {
  document.title = "Drivers Policy";
  return (
    <div className="container my-5">
      <h1 className="text-center mb-4">aDrive Global Privacy Policy for Drivers</h1>
      <p>This notice explains how aDrive ("we," "us") manages the personal data of individuals using our app. It covers the types of data we collect, why we process it, our data protection measures, and your rights.</p>

      <h2>1. Introduction</h2>
      <p>aDrive Technologies, based in Pretoria, South Africa, connects drivers with riders, offering ride requests, live trip recordings, and in-app payments.</p>

      <h2>2. aDrive Platform Registration</h2>
      <ul>
        <li>Drivers must provide accurate information and comply with legal standards.</li>
        <li>Valid documents like driver’s license and vehicle insurance are required.</li>
        <li>Incorrect or incomplete details may result in account termination.</li>
      </ul>

      <h2>3. Terms of Use</h2>
      <p>All platform content and intellectual property belong to aDrive. Unauthorized modifications or misuse may lead to suspension or termination.</p>

      <h2>4. Transaction and Fees</h2>
      <ul>
        <li>Drivers pay a commission on each trip.</li>
        <li>Fees are communicated via email and may vary.</li>
        <li>Late payments incur penalties.</li>
        <li>Drivers must manage their own tax obligations.</li>
      </ul>

      <h2>5. Using the aDrive Platform</h2>
      <ul>
        <li>Submit all required documents before using the platform.</li>
        <li>Accept or decline ride requests at your discretion.</li>
        <li>Handle cancellations according to aDrive guidelines.</li>
        <li>Fuel and insurance costs are the driver’s responsibility.</li>
      </ul>

      <h2>6. Categories of Personal Data We Process</h2>
      <ul>
        <li>
          <strong>General Data:</strong> Name, email, phone number.
        </li>
        <li>
          <strong>Geolocation Data:</strong> User location via the app.
        </li>
        <li>
          <strong>Verification Data:</strong> Email address, photo, and identification.
        </li>
        <li>
          <strong>Communication Records:</strong> Emails, chat messages, and customer support interactions.
        </li>
        <li>
          <strong>User-Generated Content:</strong> Audio recordings, profile pictures, and submitted files.
        </li>
      </ul>

      <h2>7. Reasons for Processing Personal Data</h2>
      <ul>
        <li>Location tracking ensures smooth navigation.</li>
        <li>Compliance checks verify the registered user’s identity.</li>
        <li>Security measures aid dispute resolution and legal compliance.</li>
      </ul>

      <h2>8. Payment Methods</h2>
      <ul>
        <li>Drivers must accept both cash and card payments.</li>
        <li>Optional tips can be given via the app.</li>
        <li>Refusing card payments may result in penalties.</li>
      </ul>

      <h2>9. Data Security</h2>
      <p>All user data is kept confidential. Real-time recordings are available for safety but remain private.</p>

      <h2>10. Driver and aDrive Consent</h2>
      <p>By using the app, drivers consent to ride notifications, tracking, in-app payments, and account monitoring.</p>

      <h2>11. User Rating</h2>
      <ul>
        <li>High ratings can lead to more ride requests.</li>
        <li>Low ratings may result in an investigation and possible suspension.</li>
        <li>Professionalism, clean vehicles, and safe driving impact ratings.</li>
      </ul>

      <h2>12. Agreement Termination and Account Suspension</h2>
      <ul>
        <li>aDrive may suspend or terminate accounts for violations.</li>
        <li>Users may terminate agreements with a 7-day notice.</li>
      </ul>

      <h2>13. Information Updates</h2>
      <p>Drivers must keep profile and banking details up to date to avoid issues.</p>

      <h2>14. Data Management</h2>
      <ul>
        <li>To request account deletion, contact info@adriveapp.com or use the app.</li>
        <li>Data is retained for service provision and legal compliance.</li>
      </ul>
    </div>
  );
};

export default DriverPrivacyPolicy;
