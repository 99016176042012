import React from "react";

const PrivacyPolicy = () => {
  document.title = "Riders Policy";
  return (
    <div className="container my-5">
      <h1 className="text-center mb-4">aDrive Global Privacy Policy for Riders</h1>
      <p>This notice explains how aDrive ("we," "us") manages the personal data of individuals using our app. It covers the types of data we collect, why we process it, our data protection measures, and your rights.</p>

      <h2>1. aDrive App Overview</h2>
      <ul>
        <li>
          <strong>Service:</strong> aDrive connects you with independent drivers but does not provide transport services directly.
        </li>
        <li>
          <strong>Payments:</strong> Pay via cash or the in-app system. Corporate rides have separate agreements.
        </li>
        <li>
          <strong>Mobile Number:</strong> Your number is linked to your account. Notify us within 7 days if it changes.
        </li>
      </ul>

      <h2>2. Categories of Personal Data We Process</h2>
      <ul>
        <li>
          <strong>General Data:</strong> Name, email, and phone number.
        </li>
        <li>
          <strong>Geolocation Data:</strong> Location collected via the aDrive app.
        </li>
        <li>
          <strong>Verification Data:</strong> Email address, photo, and identification details.
        </li>
        <li>
          <strong>Communication Records:</strong> Emails, chat messages, and customer support interactions.
        </li>
        <li>
          <strong>User-Generated Content:</strong> Audio recordings, profile pictures, and submitted files.
        </li>
      </ul>

      <h2>3. Reasons for Processing Personal Data</h2>
      <ul>
        <li>
          <strong>Location:</strong> Helps drivers navigate using Maps.
        </li>
        <li>
          <strong>Compliance Checks:</strong> Verification for user safety.
        </li>
        <li>
          <strong>Security and Legal Claims:</strong> Dispute resolution, app security, and legal compliance.
        </li>
      </ul>

      <h2>4. Promo Codes</h2>
      <p>Promo codes can be applied to rides or services. Misuse may result in cancellation.</p>

      <h2>5. In-App Payment System</h2>
      <ul>
        <li>
          <strong>Facilitation:</strong> Payments are processed through aDrive.
        </li>
        <li>
          <strong>Fees:</strong> We are not responsible for third-party fees.
        </li>
        <li>
          <strong>Support:</strong> Contact info@adriveapp.com for payment issues.
        </li>
      </ul>

      <h2>6. Booking and Cancellation Policies</h2>
      <ul>
        <li>
          <strong>Booking:</strong> A ride is confirmed when accepted by a driver.
        </li>
        <li>
          <strong>Cancellation Fees:</strong> Late cancellations may incur fees.
        </li>
        <li>
          <strong>Driver No-Shows:</strong> Waiting time charges may apply.
        </li>
      </ul>

      <h2>7. License to Use the aDrive App</h2>
      <p>Users are granted a non-exclusive, revocable license to use the app.</p>

      <h2>8. Liability Limitations</h2>
      <ul>
        <li>
          <strong>Quality Assurance:</strong> aDrive does not guarantee transport quality.
        </li>
        <li>
          <strong>Indemnification:</strong> Users agree to indemnify aDrive from claims.
        </li>
      </ul>

      <h2>9. Proper Use of the aDrive App</h2>
      <ul>
        <li>
          <strong>Issue Resolution:</strong> Address issues with the provider or authorities.
        </li>
        <li>
          <strong>Feedback:</strong> Use the app’s feedback system.
        </li>
      </ul>

      <h2>10. Governing Law and Dispute Resolution</h2>
      <p>Terms are governed by South African law. Disputes are resolved in Pretoria High Court.</p>

      <h2>11. Account Management and Data Privacy</h2>
      <ul>
        <li>
          <strong>Account Deletion:</strong> Request deletion via info@adriveapp.com.
        </li>
        <li>
          <strong>Data Retention:</strong> Data is retained as required by law.
        </li>
      </ul>
    </div>
  );
};

export default PrivacyPolicy;
